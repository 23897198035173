module.exports = {
    home: {
        title: 'Congratulazioni! Vinci il premio!'
    },
    redeem: {
        tip1: 'Nota: \n' +
            '①Partecipanti: Clienti che hanno acquistato a proprie spese.\n' +
            '②Ogni cliente può riscattare una sola volta.',
        tip2: 'Iniziamo.\n' +
            'Si prega di inserire il codice di riscatto.',
        code: 'Codice di riscatto：',
        codeAlert: 'Inserisci il codice di riscatto corretto.',
    },
    warranty: {
        name: 'Nome:',
        nameAlert: 'Per favore inserisci il tuo nome.',
        email: 'Email:',
        emailAlert: 'Si prega di inserire un indirizzo email valido.',
        emailAlert2: 'Si prega di inserire un indirizzo email valido.',
        order: 'Numero d\'ordine Amazon:',
        orderTip: 'Esempio: 406-1234567-1234567(17 cifre)',
        orderAlert: 'Inserisci il numero d\'ordine Amazon corretto.',
        otherContactTip: 'Nota: per favore lascia il tuo ID WhatsApp nel caso in cui non possiamo contattarti tramite e-mail.',
        whatsapp: 'ID WhatsApp:',
        whatsappTip: 'Esempio: +39 333 123 1234',
        warningTip: 'Nota: le tue informazioni saranno altamente riservate. Non preoccuparti.',
        otherContactAlert: 'per favore lascia il tuo ID WhatsApp nel caso in cui non possiamo contattarti tramite e-mail.'
    },
    finished: {
        title1: 'Grazie per il tuo tempo\n\nTi invieremo il codice della carta regalo Amazon da riscattare una volta visualizzata la tua recensione. Di solito ci vorranno 3-5 giorni.\n\nSi prega gentilmente di controllare la tua email se non la ricevi.',
        dissatisfied: 'Grazie per aver dedicato del tempo a compilare le informazioni.'
    },
    survey: {
        title: 'Sei soddisfatto del nostro prodotto?',
        option1: 'SÌ',
        option2: 'NO',
        dissatisfiedAlert: 'Sei soddisfatto del nostro prodotto?',
        reportTip1: 'Condividi la tua insoddisfazione con noi in modo che possiamo continuare a migliorare il nostro prodotto. Sosteniamo i nostri prodotti e faremo del nostro meglio per risolvere il tuo problema.',
        reportAlert: 'Si prega di condividere la tua opinione nel modulo.'
    },
    review: {
        title: 'È il nostro onore.',
        reviewTip1: 'Potresti gentilmente dedicare un po\' di tempo a scrivere una recensione per il nostro prodotto? Allega anche lo screenshot della tua recensione.',
        reviewStep1: '1. Una volta che vi sarete loggati con il vostro Account andate nella sezione <b>Ordini</b> sempre in alto a destra vicino alla voce del vostro Profilo.',
        reviewStep2: '2. Quando sarete dentro la schermata Ordini potrete vedere i vostri acquisti con a fianco la possibilità di lasciare una recensione dal pulsante <b>Scrivi una recensione per il prodotto</b>.',
        reviewStep3: '3. Una volta cliccato sul pulsante saremo pronti per scrivere la nostra recensione potendo inserire foto, video, stelle, titolo e una descrizione di come ci siamo trovati con il prodotto. (Non includere l\'immagine del gratta e vinci.)',
        reviewStep4: '4. Inviateci anche due screenshot per farci sapere che avete inviato un recensione.',
        reviewStep41: '① Rivedi prima di inviare',
        reviewStep42: '② Revisione inviata',
        reviewTip2: 'Ti invieremo il codice della carta regalo da riscattare una volta che la tua recensione sarà pubblicata sulla pagina delle recensioni di Amazon.',
        reviewTip3: 'Di solito ci vorranno 2-5 giorni.',
        imageTip: 'Clicca qui per fornire lo screenshot della tua recensione',
        imageAlert: 'Rivedi l\'immagine richiesta.',
        dialog: {
            title: 'Si prega di confermare',
            cancel: 'Errato, inviare nuovamente',
            submit: 'Corretto, sottometti'
        }
    },
    alert: {
        90001: 'Il numero d\'ordine è già esistito.',
        90002: 'Assicurati che il tuo ordine sia stato effettuato sul marketplace Amazon.it.',
        90003: 'La tua recensione è in attesa di Amazon. Di solito ci vorranno 2-3 giorni. Per favore sii paziente.',
        90004: 'Ogni cliente può riscattare una sola volta. Grazie per la tua comprensione.',
        90005: 'Grazie per il vostro continuo sostegno.\nMi scuso, ma il suo numero d\'ordine non è idoneo per la promozione Happy Scratch. (Non possono partecipare al concorso coloro che partecipano al concorso tramite tweet o coloro che richiedono assistenza post-vendita). Tuttavia, la preghiamo di notare che non saremo in grado di soddisfare nuovamente questa richiesta in futuro.\nVi ringraziamo per la vostra comprensione.',
    },
    next: 'Prossimo',
    previous: 'Precedente',
    submit: 'Sottoscrivi',
}